<template>
    <div>
        <!--begin::customer-->

        <div class="card-body p-0">
            <div class="form-group row">
                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.business_name')}}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.business_name" class="form-control" :class="validation && validation.business_name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.business_name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.business_name[0] }}
                            </span>
                </div>
              <div class="col-lg-6  mb-5">
                <label>{{ $t('suppliers.supplier_code') }}</label>
                <input type="text" v-model="data.supplier_code" class="form-control" :class="validation && validation.supplier_code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.supplier_code" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.supplier_code[0] }}
                                        </span>
              </div>
                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.first_name')}}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.first_name" class="form-control" :class="validation && validation.first_name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.first_name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.first_name[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.last_name')}}</label>
                    <input type="text" v-model="data.last_name" class="form-control" :class="validation && validation.last_name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.last_name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.last_name[0] }}
                            </span>
                </div>

                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.telephone')}}</label>
                    <!-- <input type="text" v-model="data.phone" class="form-control" :class="validation && validation.telephone ? 'is-invalid' : ''"/> -->
                    <vue-tel-input v-model="data.phone" :class="validation && validation.phone ? 'is-invalid' : ''"></vue-tel-input>

                    <span v-if="validation && validation.telephone" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.telephone[0] }}
                    </span>
                </div>
                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.mobile')}}<span class="text-danger">*</span></label>
                    <!-- <input type="text" v-model="data.mobile" class="form-control" :class="validation && validation.mobile ? 'is-invalid' : ''"/> -->
                    <vue-tel-input v-model="data.mobile" :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
                    <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.mobile[0] }}
                    </span>
                </div>

                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.country')}}</label>
                    <multiselect v-model="country"
                                 :placeholder="$t('suppliers.country')"
                                 label="name" track-by="code2"
                                 :options="countries"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @search-change="getCountries($event)"
                                 :internal-search="false">
                    </multiselect>
                  <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters')+' '+$t('suppliers.country') }}.</span>

                    <span v-if="validation && validation.country_code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.country_code[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.city')}}</label>
                  <div class="input-group mb-3">
                    <multiselect v-model="city"
                                 :placeholder="$t('suppliers.city')"
                                 label="name" track-by="id"
                                 :options="cities"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                  <div class="input-group-prepend">
                      <a class="btn btn-primary" :href="data.country_code?'/settings/cities/'+data.country_code:'#'" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                    <span v-if="validation && validation.city_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.city_id[0] }}
                            </span>
                </div>

                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.state')}}</label>
                    <input type="text" class="form-control" :class="validation && validation.state ? 'is-invalid' : ''" v-model="data.state"/>
                    <span v-if="validation && validation.state" class="fv-plugins-message-container invalid-feedback">
                                  {{ validation.state[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.postal_code')}}</label>
                    <input type="text" class="form-control" :class="validation && validation.postal_code? 'is-invalid': ''" v-model="data.postal_code"/>
                    <span v-if="validation && validation.postal_code" class="fv-plugins-message-container invalid-feedback">
                                  {{ validation.postal_code[0] }}
                                  </span>
                </div>

                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.address_1')}}</label>
                    <input type="text" class="form-control" :class="  validation && validation.address_1 ? 'is-invalid' : ''" v-model="data.address_1"/>
                    <span v-if="validation && validation.address_1" class="fv-plugins-message-container invalid-feedback">
                                  {{ validation.address_1[0] }}
                                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.address_2')}}</label>
                    <input type="text" class="form-control" :class=" validation && validation.address_2 ? 'is-invalid' : ''" v-model="data.address_2"/>
                    <span v-if="validation && validation.address_2" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.address_2[0] }}
                              </span>
                </div>

                <div class="col-lg-6 mb-5">
                    <label>{{$t('suppliers.is_active')}}</label>
                    <div class="input-group">
                        <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch></b-form-checkbox>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-footer pl-0 pr-0">
            <div class="row">
                <div class="col-lg-6">
                    <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                    <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
                </div>
            </div>
        </div>

        <!--end::customer-->
    </div>
</template>
<script>
    import ApiService from "@/core/services/api.service";
    import Vue from "vue";

    export default {
        name: "form-suppliers-modal",
        props: {
            hideModal: {type: Function},
            handlingData: {type: Function},
        },
        data() {
            return {
                mainRoute: 'purchases/suppliers',
              mainRouteDependency: 'base/dependency',
                data: {
                    business_name: "",
                    first_name: "",
                    last_name: "",
                    phone: "",
                    mobile: "",
                    country_code: "",
                    city_id: "",
                    state: "",
                    postal_code: "",
                    address_1: "",
                    address_2: "",
                    is_active: true,
                  supplier_code: "",

                },
                idEditing: this.$route.params.id,
                isEditing: false,
                countries: [],
                cities: [],
                currencies: [],
                validation: null,
                country: "",
                city: "",
            };
        },
        watch: {
            country: function (val) {
                if (val) {
                    this.data.country_code = val.code2;
                    this.getCities(val.code2);
                } else {
                    this.data.country_code = null;
                }
            },
            city: function (val) {
                if (val) {
                    this.data.city_id = val.id;
                } else {
                    this.data.city_id = null;
                }
            },
            'data.business_name': function (val) {
                if (val){
                    this.data.business_name = val.replace(/[\d]/g, "");
                }
            },
            'data.first_name': function (val) {
                if (val){
                    this.data.first_name = val.replace(/[\d]/g, "");
                }
            },
            'data.last_name': function (val) {
                if (val){
                    this.data.last_name = val.replace(/[\d]/g, "");
                }
            },
            'data.state': function (val) {
                if (val){
                    this.data.state = val.replace(/[\d]/g, "");
                }
            },
        },
        methods: {
            save() {
                this.create();
            },
            cancel() {
                this.$emit('hide-modal');
                this.resetAfterSave();
            },
            finishedSave(su_object) {
                this.$emit('handling-data', su_object);
            },
            create() {
                // this.data.is_active = this.data.is_active ? 1 : 0;
                ApiService.post(`${this.mainRoute}`, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.finishedSave(response.data.data);
                    this.cancel();
                  Vue.prototype.$postStatus = true;
                }).catch((error) => {
                  Vue.prototype.$postStatus = true;
                  this.$errorAlert(error);
                  this.validation = error.response ? error.response.data.errors : null;
                });
            },
            getCountries(filter) {
              if(filter && filter.length >= 3)
                ApiService.get("base/dependency/countries", {params:{filter: filter}}).then((response) => {
                    this.countries = response.data.data.filter(row => row.name != null);
                });
            },
            getCities(id) {
                ApiService.get(`base/dependency/cities/${id}`).then((response) => {
                    this.cities = response.data.data;
                });
            },

            resetAfterSave() {
                this.data.business_name = '';
                this.data.supplier_code = '';
                this.data.first_name = '';
                this.data.last_name = '';
                this.data.phone = '';
                this.data.mobile = '';
                this.data.country_code = '';
                this.data.city_id = '';
                this.data.state = '';
                this.data.postal_code = '';
                this.data.address_1 = '';
                this.data.address_2 = '';
                this.data.is_active = true;
            },
          getCode() {
            ApiService.get(this.mainRouteDependency + "/code-setting/2").then((response) => {
              this.data.supplier_code = response.data.data.code;
            });
          },
        },

        mounted() {
            // this.getCountries();
            this.getCode();


        },
    };
</script>